<template>
  <div class="projectCols dragContainer" v-bind:class="{ dark: isDark }">
    <SearchHeader :onAction="onSearch" :autoUpdate="true" />
    <ProjectCreator ref="editor" :onSave="editProject" />
    <div class="flexbox">
      <DragContainer :id="phase.id" :title="phase.name" v-for="phase in phases" :key="phase.id" :onContainerChange="onPhaseChange" height="60px">
        <template v-slot:actions v-if="phase.id == 'firstcontact'">
          <v-icon @click="addProject()">mdi-plus</v-icon>
        </template>
        <div class="cont">
          <DragElement :id="item.id" v-for="item in projGrp[phase.id]" :key="item.id" :draggable="true" className="projectCols">
            <v-card @click="onEdit(item.id)" class="pa-0 ma-0" :style="dragStyle" dark>
              <v-card-text>
                <h3>{{ item.name }}</h3>
                <div v-if="item.customer">{{ item.customer.name }}</div>
              </v-card-text>
            </v-card>
          </DragElement>
        </div>
      </DragContainer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { isDark, getColor } from "@/services/theme";
import ProjectCreator from "@/components/projects/projectcreator.vue";
import DragContainer from "@/components/_layout/dragcontainer.vue";
import DragElement from "@/components/_layout/dragelement.vue";
import SearchHeader from "@/components/_common/searchheader";

import { listMixin } from "@/helpers/mixins";

export default {
  mixins: [listMixin],

  data() {
    return {
      query: "",
      projects: [],
      phases: [],
      filter: ["imowner=true", "", "state=archived"],
    };
  },

  props: ["selectedFilter"],

  components: {
    ProjectCreator,
    DragContainer,
    DragElement,
    SearchHeader,
  },

  created() {
    this.loadPhases();
    this.loadProjects();
  },

  watch: {
    selectedFilter: function() {
      this.loadProjects();
    },
  },

  computed: {
    isDark: function() {
      return isDark();
    },
    dragStyle: function() {
      return `background-color: ${getColor("accent")}`;
    },
    projGrp: function() {
      let p = this.projects;
      if (this.query) {
        p = this.projects.filter((proj) => proj.name.toLowerCase().includes(this.query.toLowerCase()));
      }
      return this.groupBy(p, "projectPhaseId");
    },
  },

  methods: {
    loadPhases: function() {
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/projectphases`)
        .then((response) => {
          this.phases= [{id: "null"}].concat(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    loadProjects: function() {
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/projects?${this.filter[this.selectedFilter] || ""}`)
        .then((response) => {
          this.projects = response.data;
          this.$store.dispatch("loading", false);
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("loading", false);
        });
    },

    onSearch: function(query) {
      console.log(query);
      this.query = query;
    },

    editProject: function(prj) {
      this.onEdit(prj.id);
    },

    onEdit: function(id) {
      this.$router.push(`/projects/${id}`);
      this.$refs.editor.show(id);
    },

    addProject: function() {
      this.$refs.editor.show(null);
    },

    onPhaseChange: function(pId, projectPhaseId) {
      axios
        .post(`/api/projects/${pId}`, {
          projectPhaseId: projectPhaseId,
        })
        .then(() => {
          this.$store.dispatch("showSnackMessage", { message: "Data saved" });
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
  },
};
</script>

<style lang="scss">
.flexbox {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0 auto;
  padding: 5px;

  .dragContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 75vh;
    min-width: 200px;
    border-right: 1px dashed grey;
    margin-right: 5px;
    padding: 5px 10px 5px 5px;

    &:last-child {
      border-right: none;
    }

    .dragElement {
      cursor: pointer;
      margin-bottom: 5px;
      width: 100%;

      h3 {
        text-shadow: 0.5px 0.5px #000000;
      }
    }
  }
}

.projectCols {
  .cont {
    height: 100%;
  }

  &.sel {
    margin: 0;
    .cont {
      border: 2px dotted lightskyblue;
    }
    .current .cont {
      border: 2px dotted red;
    }
  }
}
</style>
