<template>
  <div :id="id" class="dragElement" :draggable="draggable" @dragstart="dragStart" @dragover.stop @dragend="dragend">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      default: () => "-1"
    },
    draggable: {
      default: () => false
    }
  },
  methods: {
    dragStart: e => {
      const target = e.target;
      e.dataTransfer.setData("projectId", target.id);
      
      let a = target.closest(".dragContainer")
      e.dataTransfer.setData("startConatiner", a.id);
      a.classList.add("current")
      
      setTimeout(() => {
        target.style.opacity = "0.3"
        document.getElementsByClassName("dragContainer")[0].classList.add("sel");
      }, 1);
    },
    dragend: e =>{
      e.target.style.opacity = "1";
     
      document.getElementsByClassName("dragContainer")[0].classList.remove("sel");
      document.getElementsByClassName("dragContainer")[0].querySelector(".current").classList.remove("current");
    }
  }
};
</script>
