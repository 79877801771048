<template>
  <div :id="id" class="dragContainer" @dragover.prevent @drop.prevent="drop">
    <div>
      <div class="actions"><slot name="actions" /></div>
      <h3 :height="height" :style="`height: ${height};`">{{ title }}</h3>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
    onContainerChange: {
      type: Function,
    },
    height: {
      type: String,
    },
  },

  methods: {
    drop: function(e) {
      const startConatiner = e.dataTransfer.getData("startConatiner");
      const projectId = e.dataTransfer.getData("projectId");
      const project = document.getElementById(projectId);

      if (startConatiner != this.id) {
        e.target.appendChild(project);
        this.onContainerChange(projectId, this.id);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.dragContainer {
  h3 {
    padding-bottom: 15px;
  }
  .actions {
    float: right;
  }
}
</style>
