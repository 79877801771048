<template>
  <DefaultLayout>
    <template v-slot:header>
      <FilterHeader :items="[$t('projects.filters.mine'), $t('projects.filters.all'), $t('projects.filters.archived')]" :onChange="onFilterChange" />
      <v-spacer></v-spacer>
      <AddButton :onClick="addProject" />
    </template>

    <v-layout>
      <v-flex>
        <ProjectList ref="list" :selectedFilter="selectedFilter" />
      </v-flex>
    </v-layout>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/default.vue";
import ProjectList from "@/components/projects/projectlist.vue";
import AddButton from "@/components/_common/addbutton";
import FilterHeader from "@/components/_common/filterheader";

export default {
  name: "Projects",
  data: function() {
    return {
      selectedFilter: 0,
    };
  },
  computed: {
  },
  components: {
    DefaultLayout,
    ProjectList,
    AddButton,
    FilterHeader
  },
  created() {
  },
  watch: {
  },
  methods: {
    addProject: function() {
      if (this.$refs.list) {
        this.$refs.list.addProject();
      }
    },
    onFilterChange: function(val) {
      this.selectedFilter = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
